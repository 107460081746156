import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
// @ts-ignore
import customProtocolCheck from 'custom-protocol-check'
import logo from '../icon.png'
import fallBackImage from '../fallback-image.png'

interface IPack {
  id: string
}
interface JSONPack {
  id?: string
  title?: string
  subtitle?: string
  coverPhoto?: string
  created?: string
  description?: string
  authorDisplayName?: string
  audio?: any
}

export const Pack = () => {
  const { id } = useParams<IPack>()
  const [pack, setPack] = useState<JSONPack>()
  const [, setProtocolRegistered] = useState(false)
  useEffect(() => {
    fetch(`https://nautilus.reasonstudios.com/pack/${id}/`)
      .then((response) => response.json())
      .then((data) => setPack(data))
    customProtocolCheck(
      `rslaunch://packs/${id}`,
      () => {
        setProtocolRegistered(false)
      },
      () => {
        setProtocolRegistered(true)
      },
      5000
    )
  }, [id])

  // Construct audio URL for pack
  // TODO put in setting
  const packAudioUrl: string =
    'https://d3l7thry0a8a7p.cloudfront.net/audio/' +
    pack?.audio?.audioPreviewKey

  return (
    <>
      {pack?.id ? (
        <>
          <div className="col-xs-12 col-md-8 col-lg-6 col-xl-4 text-center">
            <div
              className="cover-image mx-auto my-20"
              style={{
                backgroundImage: `url(${pack?.coverPhoto}), url(${fallBackImage})`,
              }}
            ></div>

            <div className="pack-detail-title text-center">
              <h2 className="color-white-noise">{pack?.title}</h2>
              <h4 className="color-white-noise">{pack?.subtitle}</h4>
            </div>

            <div className="pack-detail-description text-center color-white my-20">
              {pack?.description}
            </div>

            <div>
              <audio
                className="audio-player"
                controls
                controlsList="nodownload"
              >
                <source src={packAudioUrl}></source>
              </audio>
            </div>

            <div className="pack-detail-metadata d-flex justify-content-center">
              <small className="mx-10 color-white-noise ">
                <label>By:</label> {pack?.authorDisplayName}
              </small>
              <small className="mx-10 color-white-noise ">
                <label>Last updated:&nbsp;</label>
                {pack?.created?.substring(0, 10)}
              </small>
            </div>
            <hr />
          </div>
          <a href={`rslaunch://packs/${id}`} className="text-center">
            <img src={logo} alt="Reason Plus Icon" width="100" height="100" />
            <p className="color-white-noise">Open in Reason+</p>
          </a>
        </>
      ) : (
        ''
      )}
    </>
  )
}
