import React, { useEffect, useState } from 'react'
import fallBackImage from '../fallback-image.png'
import { Link } from 'react-router-dom'

export interface Pack {
  id: string
  title?: string
  subtitle?: string
  coverPhoto?: string
  created?: string
  description?: string
  authorDisplayName?: string
  audio?: any
}

export const LatestPacks = () => {
  const [packs, setPacks] = useState<Pack[]>()

  useEffect(() => {
    // TODO use a ?limit=5 when we support pagination to make this more efficient
    fetch('https://nautilus.reasonstudios.com/pack/')
      .then((response) => response.json())
      .then((data) => setPacks(data))
  }, [])

  const sortedAndSplicedPacks = packs
    ?.sort(function (a, b) {
      const id1 = Number.parseInt(a.id)
      const id2 = Number.parseInt(b.id)
      return id1 - id2
    })
    .slice(Math.max(packs?.length - 6, 1))

  const buildPackGrid = () => {
    if (packs) {
      return (
        <div className="latest-pack-grid">
        {sortedAndSplicedPacks?.map((pack: Pack) => {
            return (
              <div
                key={pack.id}
              >
                <Link to={`/pack/${pack.id}`}>
                  <div
                    className="cover-image cover-image-small mx-auto my-20"
                    style={{
                      backgroundImage: `url(${pack?.coverPhoto}), url(${fallBackImage})`,
                    }}
                  ></div>
                  <div className="pack-detail-title text-center color-white-noise">
                    {pack.title}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        )
    } else {
      return (<h2>Loading...</h2>)
    }
  }

  return (
    <>
      <h1 className="mb-10">Reason+ Packs</h1>
      <h4 className="color-white-noise">
        Here are the <b>6 latest</b> Sound Packs inside Reason+
      </h4>
      <p className="color-white-noise">
      To view more Packs,
        open the Reason+ Companion
      </p>
      {buildPackGrid()}
    </>
  )
}
