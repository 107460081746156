import React, {useState} from 'react'

export const ReasonPlusInfo = () => {
  const [hidePopup, setHidePopup] = useState(JSON.parse(localStorage.getItem('hideReasonPlusPopup') || 'false'))

  const dismissPopup = () => {
    localStorage.setItem('hideReasonPlusPopup', JSON.stringify(true))
    setHidePopup(JSON.stringify(true))
  }

  return (
    <div className={`py-5 ${hidePopup ? 'd-none' : ''} background-color-white-noise`}>
      <h3 className="text-center color-charcoal">
        Make music with Reason+
      </h3>
      <p className="mx-auto color-stone-2 fd-flex flex-column align-items-center justify-content-center text-center">
        Subscribe to inspiring sound packs, legendary
        instruments and effects and always up to date music making software.<br/>
        Click <a href="https://reasonstudios.com?ref=rplus_share">here</a> to
        learn more.
      </p>
      <p className="text-center text-underline color-stone-3" onClick={dismissPopup}>
        <u>Don't remind me again</u>
      </p>
    </div>
  )
}
