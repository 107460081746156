import React, {FunctionComponent} from 'react'
import { ReasonPlusInfo } from './ReasonPlus'
import taglineLogo from '../logo-tagline.svg'

export const RPlusLinkWrapper:FunctionComponent = (props) => {
  const {children} = props

  return (
    <div className="background-color-charcoal">
      <ReasonPlusInfo />
      <div className="wrapper-view pack-detail-view d-flex flex-column align-items-center justify-content-center pt-30 px-30">
        {children}
      </div>
      <div className="wrapper-footer text-center">
        <a href="http://www.reasonstudios.com/">
          <img src={taglineLogo} alt="Reason Plus Icon" height="40" />
        </a>
      </div>
    </div>
  )
}
