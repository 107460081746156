import './styles/App.scss'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Pack } from './components/Pack'
import { Patch } from './components/Patch'
import { LatestPacks } from './components/LatestPacks'
import { RPlusLinkWrapper } from './components/RPlusLinkWrapper'

const App = () => {
  return (
    <Router>
      <RPlusLinkWrapper>
        <Switch>
          <Route path="/pack/:id">
            <Pack />
          </Route>
          <Route path="/patch/:id">
            <Patch />
          </Route>
          <Route path="/packs/latest">
            <LatestPacks />
          </Route>
          <Route path="/">
            <LatestPacks />
          </Route>
        </Switch>
      </RPlusLinkWrapper>
    </Router>
  )
}

export default App
