import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import logo from '../icon.png'
// @ts-ignore
import customProtocolCheck from 'custom-protocol-check'
interface IPatch {
  id: string
}
interface JSONPatch {
  id?: string
  patchName?: string
  created?: string
  description?: string
  authorDisplayName?: string
  patchAudio?: any
}

export const Patch = () => {
  const { id } = useParams<IPatch>()
  const [patch, setPatch] = useState<JSONPatch>()
  const [, setProtocolRegistered] = useState(false)
  useEffect(() => {
    fetch(`https://nautilus.reasonstudios.com/patch/${id}/`)
      .then((response) => response.json())
      .then((data) => setPatch(data))
    customProtocolCheck(
      `rslaunch://patches/${id}`,
      () => {
        setProtocolRegistered(false)
      },
      () => {
        setProtocolRegistered(true)
      },
      5000
    )
  }, [id])

  // Construct audio URL for patch
  // TODO put in setting
  const patchAudioUrl: string =
    'https://d3l7thry0a8a7p.cloudfront.net/audio/' +
    patch?.patchAudio?.audioPreviewKey

  return (
    <>
      {patch?.id ? (
        <>
          <div className="col-xs-12 col-md-8 col-lg-6 col-xl-4 text-center">
            <div className="pack-detail-title text-center">
              <h2 className="color-white-noise">{patch?.patchName}</h2>
            </div>

            <div className="pack-detail-description text-center color-white my-20">
              {patch?.description}
            </div>

            <div>
              <audio
                className="audio-player"
                controls
                controlsList="nodownload"
              >
                <source src={patchAudioUrl}></source>
              </audio>
            </div>

            <div className="pack-detail-metadata d-flex justify-content-center">
              <small className="mx-10 color-white-noise ">
                <label>Last updated:&nbsp;</label>
                {patch?.created?.substring(0, 10)}
              </small>
            </div>
            <hr />
          </div>
          <a href={`rslaunch://patches/${id}`} className="text-center align-items-center">
            <img src={logo} alt="Reason Plus Icon" width="100" height="100" />
            <p className="color-white-noise">Open in Reason+</p>
          </a>
        </>
      ) : (
        ''
      )}
    </>
  )
}
